<div class="flex-row margin-30">
    <div class="flex-1"></div>
    <div class="flex-3">
        <h3 class="underlined">Administration Settings</h3>

        <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%;" mode="indeterminate"></mat-progress-bar>

        <div class="scrollable-table">
            <table mat-table [dataSource]="settings" class="mat-elevation-z8">
                <ng-container matColumnDef="key">            
                    <th mat-header-cell *matHeaderCellDef>Setting</th>
                    <td mat-cell *matCellDef="let element">{{element.settingKey}}</td>
                </ng-container>
        
                <ng-container matColumnDef="value">            
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let element">{{element.settingValue}}</td>
                </ng-container>

                <ng-container matColumnDef="action">            
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button 
                                [disabled]="loading" 
                                (click)="getAdminSettings()" 
                                class="span-form margin-tb-10 float-right">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button 
                                (click)="editSetting(element)" 
                                matTooltip="Edit Value"
                                class="span-form margin-tb-10">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="settingsColumnHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: settingsColumnHeaders;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No settings found.</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="flex-1"></div>
</div>