import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ServiceProvider } from 'src/app/models/service_provider.model';
import { ServiceProviderService } from 'src/app/services/service_provider_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from '@angular/forms';
import { PaginationHeaderData } from 'src/app/models/pagination-header-data.model';

@Component({
    selector: 'provider-table',
    templateUrl: './provider_table.component.html',
    styleUrls: ['./provider_table.component.scss']
})
export class ProviderTableComponent {
    currentPage = 1;
    moreServiceProvidersToLoad = false;
    loading = false;

    serviceProviders: ServiceProvider[] = [];
    serviceProviderDataSource = new MatTableDataSource<ServiceProvider>([]);

    serviceProviderColumnHeaders: string[] = [
        'name',
        'businessName',
        "stripeAccountId",
        'dateCreated',
        'primaryPhoneNumber',
        'secondaryPhoneNumber',
        'emailAddress',
        'address',
        'hasActiveServicePlan'
    ];

    searchFormGroup = new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        stripeAccountId: new FormControl('')
    })

    constructor(
        private serviceProviderService: ServiceProviderService,
        private tokenStorageService: TokenStorageService,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    getAllProviders(): void {
        this.loading = true;
        const queryParams = new Map([
            ['firstName', this.searchFormGroup.controls['firstName'].value],
            ['lastName', this.searchFormGroup.controls['lastName'].value],
            ['stripeAccountId', this.searchFormGroup.controls['stripeAccountId'].value],
            ["pageNumber", this.currentPage.toString()],
        ]);

        this.serviceProviderService.getPaginatedProviders(['Paged'], queryParams).subscribe(result => {
            let paginationData: PaginationHeaderData = JSON.parse(result.headers.get('x-pagination'));
            this.moreServiceProvidersToLoad = paginationData.hasNext;
            this.serviceProviders = [...this.serviceProviders, ...result.body];
            console.log(this.serviceProviders)
            this.loading = false;
        }, () => { this.loading = false });
    }

    loadMoreProviders(): void {
        this.currentPage++;
        this.getAllProviders();
    }

    updateSearchParams(): void {
        this.serviceProviders = [];
        this.currentPage = 1;
        this.getAllProviders();
    }
}
