<div class="scrollable-table">
    <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%" mode="indeterminate"></mat-progress-bar>

    <div class="content mt-3">
        <form [formGroup]="searchFormGroup">
            <div class="flex-row">
                <h1 class="mt-3">Providers</h1>

                <mat-form-field class="example-form-field ml-3" appearance="outline">
                    <mat-label>First Name</mat-label>

                    <input matInput type="text" formControlName="firstName">
                </mat-form-field>

                <mat-form-field class="example-form-field ml-3" appearance="outline">
                    <mat-label>Last Name</mat-label>

                    <input matInput type="text" formControlName="lastName">
                </mat-form-field>

                <mat-form-field class="example-form-field ml-3" appearance="outline">
                    <mat-label>Stripe Account ID</mat-label>
                
                    <input matInput type="text" formControlName="stripeAccountId">
                </mat-form-field>

                <button mat-raised-button (click)="updateSearchParams()" color="primary" class="mt-3 ml-3" style="height: 40px;">Search</button>
            </div>
        </form>

        <table mat-table [dataSource]="serviceProviders" class="mat-elevation-z8">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.firstName + " " + element.lastName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="businessName">
                <th mat-header-cell *matHeaderCellDef>Business</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.businessName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="stripeAccountId">
                <th mat-header-cell *matHeaderCellDef>Stripe Account Id</th>
            
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.stripeAccountId !== null">
                        {{ element.stripeAccountId }}
                    </ng-container>
                    <ng-container *ngIf="element.stripeAccountId === null">
                        Not Set
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="dateCreated">
                <th mat-header-cell *matHeaderCellDef>Created</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.createDateUTC | date: "shortDate" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="primaryPhoneNumber">
                <th mat-header-cell *matHeaderCellDef>Primary Phone Number</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.primaryPhoneNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="secondaryPhoneNumber">
                <th mat-header-cell *matHeaderCellDef>
                    Secondary Phone Number
                </th>

                <td mat-cell *matCellDef="let element">
                    {{ element.secondaryPhoneNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef>Email Address</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.emailAddress }}
                </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>Address</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.address }}, {{ element.city }}, {{ element.state }} {{ element.zipCode }}
                </td>
            </ng-container>

            <ng-container matColumnDef="hasActiveServicePlan">
                <th mat-header-cell *matHeaderCellDef>Active</th>

                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="!element.hasActiveServicePlan" class="error">clear</mat-icon>

                    <mat-icon *ngIf="element.hasActiveServicePlan" class="success">check</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="serviceProviderColumnHeaders"></tr>

            <tr mat-row *matRowDef="let row; columns: serviceProviderColumnHeaders"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Use search fields to find Providers</td>
            </tr>
        </table>

        <footer>
            <button mat-raised-button [hidden]="!moreServiceProvidersToLoad" class="mt-3 mb-3" color="primary" (click)="loadMoreProviders()">Load More</button>
        </footer>
    </div>
</div>