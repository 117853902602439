import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ACHRecord } from 'src/app/models/ach_record.model';
import { ACHRecordService } from 'src/app/services/ach_record_service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
  selector: 'app-view-ach-data-dialog',
  templateUrl: './view-ach-data-dialog.component.html',
  styleUrls: ['./view-ach-data-dialog.component.scss']
})
export class ViewAchDataDialogComponent implements OnInit {
  hideRoutingNumber = true;
  hideAccountNumber = true;
  achRecord!: ACHRecord;

  achRecordFormGroup = new FormGroup({
    bankName: new FormControl({ disabled: true }),
    routingNumber: new FormControl({ disabled: true }),
    accountNumber: new FormControl({ disabled: true }),
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ACHRecord,
    private achRecordService: ACHRecordService,
  ) {}

  ngOnInit() {
    this.getACHRecord();
    this.setValues();
  }

  setValues(): void {
    this.achRecordFormGroup.controls.bankName.setValue(this.achRecord.bankName);
    this.achRecordFormGroup.controls.bankName.disable();
    this.achRecordFormGroup.controls.accountNumber.setValue(this.achRecord.accountNumber);
    this.achRecordFormGroup.controls.accountNumber.disable();
    this.achRecordFormGroup.controls.routingNumber.setValue(this.achRecord.routingNumber);
    this.achRecordFormGroup.controls.routingNumber.disable();
  }

  getACHRecord(): void {
    const queryParams = new Map();
    this.achRecordService.get([this.data.achRecordId], queryParams).subscribe((result) => {
      this.achRecord = result;
      this.setValues();
    })
  }
}
