import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AbstractService } from "./abstract_service.service";

@Injectable({
    providedIn: 'root'
})
export class UserService extends AbstractService {
  path = 'User';

  getPaginatedUsers(pathSegments: string[], queryParameters: Map<string, any>): Observable<any> {
		const pathSegmentString = this._buildPathSegments(pathSegments);
		const queryParameterString = this._buildQueryParameters(queryParameters);

		return this.http.get<any>(
			`${this.baseUrl}/${this.path}${pathSegmentString}${queryParameterString}`,
			{
				...{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Access-Control-Expose-Headers': ['x-pagination'],
					})
				},
				observe: 'response'
			}
		);
	}
}
