import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { ServiceType } from 'src/app/models/service_type.model';
import { ServiceTypeService } from 'src/app/services/service_type.service';

@Component({
    selector: 'service-type-table',
    templateUrl: './service_type_table.component.html',
    styleUrls: ['./service_type_table.component.scss'],
})
export class ServiceTypeTableComponent implements OnInit {
    employee!: Employee;
    serviceTypes: ServiceType[] = [];
    serviceTypeDataSource = new MatTableDataSource<ServiceType>([]);
    serviceTypeColumnHeaders: string[] = ['name', 'status', 'dateModified', 'action'];
    newServiceTypeName = '';

    serviceTypeInvalid = false;
    duplicateServiceType = false;
    successAdd = false;
    loading = false;

    constructor(
        private router: Router,
        private storage: TokenStorageService,
        private serviceTypeService: ServiceTypeService,
    ) { }

    ngOnInit(): void {
        this.employee = this.storage.getUser();
        if (this.employee.employeeRole === 'Admin') {
            this.getAllServiceTypes();
        } else {
            this.router.navigate(['home']);
        }
    }

    getAllServiceTypes(): void {
        this.loading = true;
        this.serviceTypeService
            .getAll(['All'], new Map())
            .subscribe(x => {
                this.serviceTypes = x;
                this.loading = false;
                this.refreshServiceTypeTable();
            }, (_) => this.loading = false
            );
    }

    addServiceType(): void {
        this.serviceTypeInvalid = false;
        this.duplicateServiceType = false;
        this.successAdd = false;
        this.loading = true;
        const name = this.newServiceTypeName;
        const matchingServiceTypeName = this.serviceTypes.findIndex(x => x.name.toLowerCase() === name.toLowerCase())
        if(matchingServiceTypeName > -1) {
            this.duplicateServiceType = true;
        }
        
        if (!this.duplicateServiceType) {
            this.serviceTypeService
                .post([], { name })
                .subscribe(_ => {
                    this.loading = false;
                    this.getAllServiceTypes();
                    this.successAdd = true;
                }, (_) => { this.loading = false }
                );
        } else {
            this.loading = false;
        }
    }

    toggleServiceType(serviceTypeId: string, isDelete: boolean): void {
        const serviceTypeStatus = isDelete ? 'INACTIVE' : 'ACTIVE';

        this.serviceTypeService
            .put([serviceTypeId], { serviceTypeStatus, serviceTypeId })
            .subscribe(_ => {
                this.loading = false;
                this.getAllServiceTypes();
            }, (_) => this.loading = false
            );
    }

    refreshServiceTypeTable(): void {
        this.serviceTypeDataSource.data = this.serviceTypes;
    }
}
