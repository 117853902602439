<div class="flex-row padding-30">
    <div class="flex-1"></div>

    <div class="flex-3 border pt-5 pb-5">
        <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()" class="form-content">
            <h1 class="margin-bottom-25 center-text">{{formAction}} an Employee</h1>

            <mat-form-field class="form-group flex-row formFieldWidth480">
                <mat-label>First Name *</mat-label>
                <input matInput type="text" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }" />
                <div *ngIf="submitted && formControls.firstName.errors" class="invalid-feedback">
                    <div *ngIf="formControls.firstName.errors.required">First name is required</div>
                </div>
            </mat-form-field>

            <mat-form-field class="form-group flex-row form-width">
                <mat-label>Last Name *</mat-label>
                <input matInput type="text" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }" />
                <div *ngIf="submitted && formControls.lastName.errors" class="invalid-feedback">
                    <div *ngIf="formControls.lastName.errors.required">Last name is required</div>
                </div>
            </mat-form-field>

            <mat-form-field class="form-group flex-row form-width">
                <mat-label>Email *</mat-label>
                <input matInput type="email" (change)="checkProperEmail()" formControlName="emailAddress" [ngClass]="{ 'is-invalid': submitted && formControls.emailAddress.errors }" />
                <mat-error *ngIf="formControls.emailAddress.hasError('email') || formControls.emailAddress.hasError('pattern')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="formControls.emailAddress.hasError('required')">
                    Email is required
                </mat-error>
            </mat-form-field>
            <p class="email-warn" *ngIf="!properEmail && !formControls.emailAddress.pristine">Email Requires a domain.</p>


            <mat-form-field appearance="fill" class="form-group flex-row form-width">
                <mat-label>Employee Role</mat-label>
                <mat-select [(ngModel)]="selectedEmployeeRole" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let employeeRole of employeeRoles | keyvalue" [value]="employeeRole.key">
                        {{employeeRole.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="form-group flex-row form-width">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" minlength="8" />
                <mat-error *ngIf="formControls.password.hasError('pattern') || formControls.password.hasError('minlength')">Password does not meet requirements</mat-error>
            </mat-form-field>
            <ul class="border password-param">
                <li>Minimum eight characters</li>
                <li>At least one upper case letter</li>
                <li>At least one lower case letter</li>
                <li>At least one digit</li>
                <li>At least one special of the following special character<br/>#?!@$^&*-</li>
            </ul>



            <div class="flex-row">
                <div class="flex-2"></div>
                <button mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary btn-size flex-3">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <div class="flex-2"></div>
            </div>
        </form>
    </div>

    <div class="flex-1">
        <button mat-icon-button (click)="goBack()" class="float-right" aria-label="Return to employees screen">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>