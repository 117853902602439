import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee_service.service';

@Component({
    selector: 'upsert-employee',
    templateUrl: './upsert_employee.component.html',
    styleUrls: ['./upsert_employee.component.scss'],
})
export class UpsertEmployeeComponent implements OnInit {
    properEmail: boolean = false;
    employeeId: any;
    employee: any;

    // Exclude the role from the form because material select is dumb with reactive forms...
    // Plus, we have default options for a reason so we don't need required validation.
    selectedEmployeeRole = 'Admin';

    employeeRoles = new Map([
        ['Admin', 'Admin'],
        ['Associate', 'Associate'],
    ]);

    get formAction(): string {
        return this.employeeId != null ? 'Update' : 'Create';
    }

    employeeForm = new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        emailAddress: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
    });

    get formControls(): { [p: string]: AbstractControl } {
        return this.employeeForm.controls;
    }

    get formValues(): any {
        return this.employeeForm.value;
    }

    loading = false;
    submitted = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private employeeService: EmployeeService,
    ) {
        this.employeeForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            emailAddress: ['', Validators.required],
            password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]),
        });
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.employeeId = params.get('id');
            const employeeId = this.employeeId;

            if (this.employeeId != null) {


                this.employeeService.get([employeeId], new Map()).subscribe(response => {
                    if (typeof response.employeeRole === 'string') {
                        this.selectedEmployeeRole = String(this.employeeRoles.get(response.employeeRole));
                    }

                    this.employeeForm = this.formBuilder.group({
                        firstName: [response.firstName, Validators.required],
                        lastName: [response.lastName, Validators.required],
                        emailAddress: [response.emailAddress, Validators.required],
                        password: [''],
                    });
                });
            }
        });
    }

    checkProperEmail(): void {
        this.properEmail = false;
        if(this.employeeForm.controls.emailAddress.value.includes('.')){
          this.properEmail = true;
        }
      }

    goBack(): void {
        this.router.navigate(['employee']);
    }

    onSubmit(): void {
        const employeeId = this.employeeId;
        const emailAddress = this.formValues.emailAddress;
        const password = this.formValues.password;
        const employeeRole = this.selectedEmployeeRole;
        const firstName = this.formValues.firstName;
        const lastName = this.formValues.lastName;

        if (this.employeeId != null) {
            this.employeeService
                .put([employeeId], {
                    employeeId,
                    emailAddress,
                    password,
                    employeeRole,
                    firstName,
                    lastName,
                })
                .subscribe(x => this.router.navigate(['home'])
            );
        } else {
            this.employeeService
                .post([], {
                    emailAddress,
                    password,
                    employeeRole,
                    firstName,
                    lastName,
                })
                .subscribe(x => this.router.navigate(['home'])
            );
        }
    }
}
