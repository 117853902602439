<div class="flex-row margin-tb-10">
    <div class="flex-1"></div>

    <mat-accordion class="headers-align flex-3" multi>
        <mat-expansion-panel [expanded]="true">
            <mat-progress-bar *ngIf="loadingServiceApplication" style="height: 5px;" mode="indeterminate"></mat-progress-bar>

            <mat-expansion-panel-header>
                <mat-panel-title>
                    Details
                </mat-panel-title>
                <mat-panel-description>
                    <span class="{{getDemographicReviewStatus}}">{{getDemographicReviewStatus}}</span>
                    <mat-icon>person</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <hr>

            <div *ngIf="!loadingServiceApplication" class="margin-lr-20">
                <h2>
                    {{serviceApplication?.serviceProviderDemographicModel?.firstName + " " + serviceApplication?.serviceProviderDemographicModel?.lastName}}
                </h2>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">Email</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.emailAddress}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">Address</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.address}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">City</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.city}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">State</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.state}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">Zip Code</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.zipCode}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">Primary Phone Number</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.primaryPhoneNumber}}</div>
                </div>

                <div class="flex-row padding-5">
                    <div class="flex-1 bold-text">Secondary Phone Number</div>
                    <div class="flex-3">{{serviceApplication?.serviceProviderDemographicModel?.secondaryPhoneNumber}}</div>
                </div>
            </div>

            <div class="padding-20 flex-row float-right">
                <mat-checkbox class="margin-lr-10" [(ngModel)]="demographicsValid">Are these details acceptable?</mat-checkbox>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Files
                </mat-panel-title>
                <mat-panel-description>
                    <span class="{{getFilesReviewStatus}}">{{getFilesReviewStatus}}</span>
                    <mat-icon>attach_file</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <hr>

            <div class="flex-row margin-lr-20">
                <div class="flex-1">    
                    <h2>{{serviceApplication?.serviceProviderDemographicModel?.firstName + " " + serviceApplication?.serviceProviderDemographicModel?.lastName}}'s Files</h2>
                    
                    <div class="flex-row align-items-center" *ngFor="let serviceDocument  of serviceApplication?.serviceDocumentModels">
                        <button mat-icon-button 
                                aria-label="Download this document" 
                                matTooltip="Download file"
                                (click)="downloadFile(serviceDocument)">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        
                        {{serviceDocument?.name}}
                    </div> 
                </div>              
            </div>  

            <div class="padding-20 flex-row float-right">
                <mat-checkbox class="margin-lr-10" [(ngModel)]="filesValid">Are the files valid?</mat-checkbox>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Service Plan
                </mat-panel-title>
                <mat-panel-description>
                    <span class="{{getServicePlansReviewStatus}}">{{getServicePlansReviewStatus}}</span>
                    <mat-icon>attach_money</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <hr>

            <div class="margin-lr-20">
                <div *ngFor="let applicationServicePlan of serviceApplication?.serviceApplicationServicePlanModels; let j = index">
                    <h2>Service Plan {{j+1}}</h2>

                    <div class="flex-row padding-5 max-width-600">
                        <div class="flex-1 bold-text">Provider</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.firstName + " " + applicationServicePlan?.servicePlanModel?.serviceProviderModel?.lastName}}</div>
                    </div>

                    <div class="flex-row padding-5 max-width-600">
                        <div class="flex-1 bold-text">Provider Location</div>
                        <div class="flex-1">
                            {{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.address}}<br>
                            {{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.city}}
                            {{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.state}}<br>
                            {{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.zipCode}}
                        </div>
                    </div>

                    <div class="flex-row padding-5 max-width-600">
                        <div class="flex-1 bold-text">Provider Phone Number</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.serviceProviderModel?.primaryPhoneNumber}}</div>
                    </div>

                    <div class="flex-row padding-5 max-width-600">
                        <div class="flex-1 bold-text">Service Type</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.serviceTypeModel?.name}}</div>
                    </div> 

                    <div class="flex-row padding-5 max-width-600" *ngIf="applicationServicePlan?.servicePlanModel?.description != null ">
                        <div class="flex-1 bold-text">Description</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.description}}</div>
                    </div>

                    <div class="flex-row padding-5 max-width-600" *ngIf="applicationServicePlan?.servicePlanModel?.rate != null ">
                        <div class="flex-1 bold-text">Rate</div>
                        <div class="flex-1">${{applicationServicePlan?.servicePlanModel?.rate}}</div>
                    </div>

                    <div class="flex-row padding-5 max-width-600" *ngIf="applicationServicePlan?.servicePlanModel?.rateType != null ">
                        <div class="flex-1 bold-text">Rate Type</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.rateType}}</div>
                    </div>

                    <div class="flex-row padding-5 max-width-600" *ngIf="applicationServicePlan?.servicePlanModel?.hsaApproved != null ">
                        <div class="flex-1 bold-text">Is HSA Accepted?</div>
                        <div class="flex-1">{{applicationServicePlan?.servicePlanModel?.hsaApproved ? 'Yes' : 'No'}}</div>
                    </div>
                    
                    <hr>
                </div>
            </div>

            <div class="padding-20 flex-row float-right">
                <mat-checkbox class="margin-lr-10" [(ngModel)]="servicePlansValid">Are the selected service plans valid?</mat-checkbox>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Notes
                </mat-panel-title>
                <mat-panel-description>
                    <span></span>
                    <mat-icon>comment_outline</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <hr>

            <div class="text-box-width flex-row">
                <mat-form-field class="flex-3">
                    <mat-label>Leave a note</mat-label>
                    <textarea matInput placeholder="This needs work because..." [(ngModel)]="note"></textarea>
                </mat-form-field>

                <div class="flex-1 margin-20">
                    <button mat-stroked-button 
                            color="primary" 
                            aria-label="Save comment" 
                            (click)="postNote()">
                        Save
                    </button>
                </div>
            </div>

            <mat-progress-bar *ngIf="loadingNotes" style="height: 4px;" mode="indeterminate"></mat-progress-bar>

            <div class="scrollable-table">
                <table mat-table [dataSource]="applicationNoteDataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="name">            
                        <th mat-header-cell *matHeaderCellDef class="padding-5">Note</th>
                        <td mat-cell *matCellDef="let element" class="cell-min-width padding-5">{{element.note}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="employee">            
                        <th mat-header-cell *matHeaderCellDef class="padding-5">Employee</th>
                        <td mat-cell *matCellDef="let element" class="cell-min-width padding-5">{{element.employeeModel.emailAddress}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="date">            
                        <th mat-header-cell *matHeaderCellDef class="padding-5">Date</th>
                        <td mat-cell *matCellDef="let element" class="cell-min-width padding-5">{{element.createDateUTC | date:'medium'}}</td>
                    </ng-container>
        
                    <ng-container matColumnDef="action">            
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button 
                                    (click)="deleteNote(element.applicationNoteId)" 
                                    matTooltip="Delete note"
                                    class="span-form margin-tb-10">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="applicationNoteColumnHeaders"></tr>
                    <tr mat-row *matRowDef="let row; columns: applicationNoteColumnHeaders;"></tr>
        
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No notes found.</td>
                    </tr>
                </table>
            </div>   
        </mat-expansion-panel>

        <div class="margin-tb-10 margin-top-30">
            <mat-progress-bar *ngIf="saving" style="height: 5px;" mode="indeterminate"></mat-progress-bar>
        </div>

        <div class="margin-tb-10 margin-top-30">
            <button *ngIf="!saving"
                    mat-raised-button 
                    color="primary" 
                    class="button-full-width margin-top-30" 
                    (click)="onNeedsWorkServiceApplication()">
                Needs Work
            </button>
        </div>

        <div class="margin-tb-10">
            <button *ngIf="!saving"
                    mat-raised-button
                    color="primary" 
                    class="button-full-width margin-top-30" 
                    (click)="onDenyServiceApplication()">
                Deny
            </button>
        </div>

        <div class="button-full-width">            
            <button *ngIf="!saving"
                    [disabled]="!demographicsValid || !filesValid || !servicePlansValid"
                    mat-raised-button 
                    color="primary" 
                    class="button-full-width" 
                    (click)="onApproveServiceApplication()">
                Approve
            </button>
        </div>
    </mat-accordion>

    <div class="flex-1 padding-30">
        <button mat-icon-button 
                matTooltip="Return to service applications"
                (click)="goBack()" 
                class="float-right" 
                aria-label="Return to service applications">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>