<div class="scrollable-table">
    <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%" mode="indeterminate"></mat-progress-bar>

    <div class="content mt-3">
        <h1>Customers</h1>

        <table mat-table [dataSource]="customers" class="mat-elevation-z8">
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef>First Name</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.firstName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef>Last Name</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.lastName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="dateOfBirth">
                <th mat-header-cell *matHeaderCellDef>DOB</th>
            
                <td mat-cell *matCellDef="let element">
                    {{ element.dateOfBirth | date: 'MM/dd/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="primaryPhoneNumber">
                <th mat-header-cell *matHeaderCellDef>Primary Phone Number</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.primaryPhoneNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="secondaryPhoneNumber">
                <th mat-header-cell *matHeaderCellDef>
                    Secondary Phone Number
                </th>

                <td mat-cell *matCellDef="let element">
                    {{ element.secondaryPhoneNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef>Email Address</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.emailAddress }}
                </td>
            </ng-container>

            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>Address</th>

                <td mat-cell *matCellDef="let element">
                    {{ element.address }}, {{ element.city }}, {{ element.state }} {{ element.zipCode }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button matTooltip="Refresh" [disabled]="loading" (click)="resetLoad()" class="span-form margin-tb-10 float-right">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </th>

                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="customerColumnHeaders"></tr>

            <tr mat-row *matRowDef="let row; columns: customerColumnHeaders"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No customers found.</td>
            </tr>
        </table>

        <footer style="height: 100px;">
            <button mat-raised-button [hidden]="!moreCustomersToLoad" [disabled]="!moreCustomersToLoad" class="mt-3 mb-3" color="primary" (click)="loadMoreCustomers()">Load More</button>
        </footer>
    </div>
</div>