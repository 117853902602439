<div class="flex-row padding-20">
    <div class="flex-1"></div>

    <div class="flex-3 scrollable-table">
        <button mat-raised-button color="primary" (click)="createEmployee()" class="span-form margin-tb-10">
            <span style="vertical-align: bottom;">+&nbsp;&nbsp;</span> New Employee
        </button>

        <mat-progress-bar *ngIf="loading" style="height: 4px;" mode="indeterminate"></mat-progress-bar>

        <table mat-table [dataSource]="employeeDataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>

                <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}</td>
            </ng-container>

            <ng-container matColumnDef="emailAddress">
                <th mat-header-cell *matHeaderCellDef>Email Address</th>

                <td mat-cell *matCellDef="let element">{{element.emailAddress}}</td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>

                <td mat-cell *matCellDef="let element">{{element.employeeRole}}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button [disabled]="loading" (click)="getAllEmployees()" class="span-form margin-tb-10 float-right">
                            <mat-icon>refresh</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="updateEmployee(element.employeeId)" class="span-form margin-tb-10 float-right" matTooltip="Edit employee details">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="employeeColumnHeaders"></tr>

            <tr mat-row *matRowDef="let row; columns: employeeColumnHeaders;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No employees found.</td>
            </tr>
        </table>
    </div>

    <div class="flex-1"></div>
</div>