import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceDocument } from '../models/service_document.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceDocumentService {

  get baseUrl(): string {
      if (window.location.href.toLowerCase().includes('https://upperemployeeportal.z13.web.core.windows.net') || window.location.href.toLowerCase().includes('https://employee.upper.health')) {
          return 'https://upper-api.azurewebsites.net';
      } else if (window.location.href.includes('localhost')) {
          return 'https://localhost:5001';
      }
      return 'https://upper-qa-api.azurewebsites.net';
  }

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  path = 'ServiceDocument';
  serviceApplicationPath = 'ServiceApplication';

  download(serviceDocumentId: string): Observable<any> {
    return this.http.get<any>(
        `${this.baseUrl}/${this.path}/${serviceDocumentId}/Download`,
        this.httpOptions,
    );
  }

  getList(): Observable<ServiceDocument[]> {
    return this.http.get<ServiceDocument[]>(
        `${this.baseUrl}/${this.path}/All`,
        this.httpOptions,
    );
  }

  get(serviceDocumentId: string): Observable<ServiceDocument> {
    return this.http.get<ServiceDocument>(
        `${this.baseUrl}/${this.path}/${serviceDocumentId}`,
        this.httpOptions,
    );
  }
}
