import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { Location } from '@angular/common';
import { ServiceApplication } from 'src/app/models/service_application.model';
import { ServiceApplicationService } from 'src/app/services/service_application_service';

@Component({
    selector: 'service-application',
    templateUrl: './service_application.component.html',
    styleUrls: ['./service_application.component.scss'],
})
export class ServiceApplicationComponent implements OnInit {
    employee!: Employee;
    serviceApplications: ServiceApplication[] = [];
    serviceApplicationDataSource = new MatTableDataSource<ServiceApplication>([]);
    serviceApplicationColumnHeaders: string[] = ['name', 'applicationStatus', 'dateSubmitted', 'action'];

    // default to submitted statuses
    selectedServiceApplicationStatus = 'Submitted';
    loading = false;

    constructor(
        private router: Router,
        private storage: TokenStorageService,
        private serviceApplicationService: ServiceApplicationService,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.employee = this.storage.getUser();
        if (this.employee.employeeRole === 'Admin' || this.employee.employeeRole === 'Associate') {
            this.getAllServiceApplications();
        } else {
            this.router.navigate(['home']);
        }
    }

    getAllServiceApplications(): void {
        this.loading = true;

        const queryParameters = new Map();
        queryParameters.set('serviceApplicationStatus', this.selectedServiceApplicationStatus);

        this.serviceApplicationService
            .getAll([], queryParameters)
            .subscribe(x => {
                this.serviceApplications = x;
                this.loading = false;
                this.refreshServiceApplicationTable();
            }, (_) => this.loading = false
        );
    }

    refreshServiceApplicationTable(): void {
        this.serviceApplicationDataSource.data = this.serviceApplications;
    }

    reviewServiceApplication(id: string): void {
        this.router.navigate(['service-application', id]);
    }
}
