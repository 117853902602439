import { Injectable } from '@angular/core';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService extends AbstractService {
    path = 'Employee';
    changePasswordPath = 'ChangePassword';
    forgotPasswordPath = 'ForgotPassword';
}

