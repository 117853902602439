<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div class="example-table-container">
    <table mat-table [dataSource]="orderServicePlans" class="table-width">
        <ng-container matColumnDef="Service Plan">
            <th mat-header-cell *matHeaderCellDef>Service Plan</th>
            <td mat-cell *matCellDef="let element">
                {{ element.servicePlan.description }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Customer">
            <th mat-header-cell *matHeaderCellDef>Customer</th>
            <td mat-cell *matCellDef="let element">
                {{element.order.userModel.firstName}} {{element.order.userModel.lastName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Date of Purchase">
            <th mat-header-cell *matHeaderCellDef>Date of Purchase</th>
            <td mat-cell *matCellDef="let element">
                {{ element.order.updateDateUTC | date: "shortDate" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Total Sale">
            <th mat-header-cell *matHeaderCellDef>Total Sale</th>
            <td mat-cell *matCellDef="let element">
                ${{ element.total.toFixed(2) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Amount Paid To Provider">
            <th mat-header-cell *matHeaderCellDef>Provider Earnings</th>
            <td mat-cell *matCellDef="let element">
                ${{ getAmountPaidToProvider(element).toFixed(2)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="Paid?">
            <th mat-header-cell *matHeaderCellDef>Paid?</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox [checked]="element.providerIsPaid" (change)="addToUpdateList(element)">
                </mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
<div class="mt-4" style="margin-left: 80%">
    <button class="mr-2" color="primary" (click)="openConfirmDialog()" mat-raised-button>
        Save
    </button>
    <button mat-raised-button (click)="closeDialog()" color="warn">
        Close
    </button>
</div>