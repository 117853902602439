<mat-progress-bar
    *ngIf="savingACHSetUpChange"
    mode="indeterminate"
></mat-progress-bar>

<div class="content mt-5 scrollable-table">
    <h1>Provider ACH Records</h1>
    <mat-divider class="content"></mat-divider>

    <table
        mat-table
        [dataSource]="achRecords"
        class="mat-elevation-z8 width-100"
    >
        <ng-container matColumnDef="Provider">
            <th mat-header-cell *matHeaderCellDef>Provider</th>

            <td mat-cell *matCellDef="let element">
                {{
                    element.serviceProviderModel.firstName +
                        " " +
                        element.serviceProviderModel.lastName
                }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Business Name">
            <th mat-header-cell *matHeaderCellDef>Business Name</th>

            <td mat-cell *matCellDef="let element">
                {{ element.serviceProviderModel.businessName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="View ACH Data">
            <th mat-header-cell *matHeaderCellDef>View ACH Data</th>

            <td mat-cell *matCellDef="let element">
                <mat-icon (click)="openACHDataDialog(element)" class="clickable"
                    >visibility</mat-icon
                >
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
