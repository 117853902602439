<div class="flex-row padding-30">
    <div class="flex-1"></div>

    <div class="flex-3">
        <h3 class="underlined">Submitted Applications</h3>

        <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%;" mode="indeterminate"></mat-progress-bar>

        <div class="scrollable-table">
            <table mat-table [dataSource]="serviceApplications" class="mat-elevation-z8">
                <ng-container matColumnDef="name">            
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{element.serviceProviderModel.firstName + " " + element.serviceProviderModel.lastName}}</td>
                </ng-container>
        
                <ng-container matColumnDef="applicationStatus">            
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{element.serviceApplicationStatus}}</td>
                </ng-container>
        
                <ng-container matColumnDef="dateSubmitted">            
                    <th mat-header-cell *matHeaderCellDef>Date Submitted</th>
                    <td mat-cell *matCellDef="let element">{{element.updateDateUTC | date:'medium'}}</td>
                </ng-container>
    
                <ng-container matColumnDef="action">            
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button 
                                [disabled]="loading" 
                                (click)="getAllServiceApplications()" 
                                class="span-form margin-tb-10 float-right">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button 
                                [disabled]="element.serviceApplicationStatus != 'Submitted'" 
                                (click)="reviewServiceApplication(element.serviceApplicationId)" 
                                matTooltip="Review this application"
                                class="span-form margin-tb-10">
                            <mat-icon>east</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="serviceApplicationColumnHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: serviceApplicationColumnHeaders;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No applications found.</td>
                </tr>
            </table>
        </div>   
    </div>
    
    <div class="flex-1"></div>
</div>

