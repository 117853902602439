<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<h1 mat-dialog-title>
  Confirm Payment
</h1>

<div mat-dialog-content>
  <p>Are you sure want to confirm payment?</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="closeDialog()">No</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
</div>