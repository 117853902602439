import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends AbstractService {
  path = 'Employee/Login';

  login(baseAuth: string): Observable<any> {
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': `Basic ${baseAuth}`,
			})
		}
		return this.post([], {});
	}
}
