import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ACHRecord } from 'src/app/models/ach_record.model';
import { ACHRecordService } from 'src/app/services/ach_record_service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
    selector: 'app-ach-update',
    templateUrl: './ach-update.component.html',
    styleUrls: ['./ach-update.component.scss']
})
export class AchUpdateComponent implements OnInit {
    currentACHRecord!: ACHRecord;
    hasSetupACHRecord: boolean = false;
    accountUpdated: boolean = false;
    loading: boolean = false;
    hideRoutingNumber = false;
    hideAccountNumber = false;

    achRecordFormGroup = new FormGroup({
        bankName: new FormControl('', [Validators.required]),
        routingNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
        accountNumber: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
        employeeId: new FormControl(),
        achRecordId: new FormControl()
    })


    constructor(
        private achRecordService: ACHRecordService,
        private userService: TokenStorageService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.achRecordFormGroup.controls.employeeId.setValue(this.userService.getUser().id);
        this.getCurrentACHRecord();
    }

    getCurrentACHRecord(): void {
        this.loading = true;
        const emptyQueryParams = new Map<string, any>([]);
        const queryParams = new Map([
            ["id", this.userService.getUser().id],
        ]);

        this.achRecordService.get(["DoesACHExist"], queryParams).subscribe((doesAccountExist) => {
            this.hasSetupACHRecord = doesAccountExist;

            if (this.hasSetupACHRecord) {
                this.achRecordService.get(['hidden'], queryParams).subscribe((achRecord: ACHRecord) => {
                    this.currentACHRecord = achRecord;
                    this.achRecordFormGroup.controls.achRecordId.setValue(this.currentACHRecord.achRecordId);
                    this.achRecordFormGroup.controls.routingNumber.setValue(this.currentACHRecord.routingNumber);
                    this.achRecordFormGroup.controls.bankName.setValue(this.currentACHRecord.bankName);
                    this.achRecordFormGroup.controls.accountNumber.setValue(this.currentACHRecord.accountNumber);
                });
            }
        });

        this.loading = false;
    }

    submitACHRecord(): void {
        this.loading = true;
        if (this.hasSetupACHRecord && this.achRecordFormGroup.valid) {
            this.achRecordService.put([this.currentACHRecord.achRecordId], this.achRecordFormGroup.getRawValue()).subscribe(() => {
                this.getCurrentACHRecord();
                this.loading = false;
                this.accountUpdated = true;
                this.openSnackBar();
                this.router.navigate(['home']);
            }, () => { this.loading = true; });
        } else if (this.achRecordFormGroup.valid) {
            this.achRecordService.post([], this.achRecordFormGroup.getRawValue()).subscribe(() => {
                this.getCurrentACHRecord();
                this.loading = false;
                this.accountUpdated = true;
                this.openSnackBar();
                this.router.navigate(['home']);
            }, () => { this.loading = false; });
        }
    }

    openSnackBar() {
        this.snackBar.open('Payment Info Update Success', 'Close', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2500,
            panelClass: ['success-snack-bar'],
        });
    }
}
