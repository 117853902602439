import { Injectable } from '@angular/core';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationNoteService extends AbstractService {
    path = 'ApplicationNote';
    applicationPath = 'Application';
    serviceApplicationPath = 'ServiceApplication';
}

