import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminErrorDialog } from '../error_dialog/error_dialog.component';
import { SettingsService } from 'src/app/services/settings_service.service';
import { Settings } from 'src/app/models/settings.model';

@Component({
    selector: 'admin-settings',
    templateUrl: './admin_settings.component.html',
    styleUrls: ['./admin_settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
    employee!: Employee;

    settings: Settings[] = [];
    settingsColumnHeaders = ['key', 'value', 'action'];

    error = false;
    loading = false;

    constructor(
        private router: Router,
        private tokenStorageService: TokenStorageService,
        private settingsService: SettingsService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.employee = this.tokenStorageService.getUser();
        if (this.employee.employeeRole === 'Admin') {
            this.getAdminSettings();
        } else {
            this.openErrorDialog();
        }
    }

    getAdminSettings(): void {
        this.loading = true;

        this.settingsService
            .getAll(['All'], new Map())
            .subscribe(
                settings => {
                    this.settings = settings;
                    this.error = false;
                    this.loading = false;
                },
                _ => {
                    this.error = true;
                    this.loading = false;
                }
            );
    }

    updateSettingsValue(setting: Settings): void {
        const settingId = setting.settingId;
        const settingKey = setting.settingKey;
        const settingValue = setting.settingValue;

        this.settingsService
            .put([setting.settingId], { settingId, settingKey, settingValue })
            .subscribe(
                _ => {
                    this.getAdminSettings();
                    this.error = false;
                },
                _ => {
                    this.error = true;
                }
            );
    }

    openErrorDialog(): void {
        const dialogRef = this.dialog.open(AdminErrorDialog);

        dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['home']);
        });
    }

    editSetting(setting: Settings): void {
        const deepCopySetting = new Settings(setting);

        const dialogRef = this.dialog.open(EditSettingsDialog, {
            width: '450px',
            data: deepCopySetting
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.updateSettingsValue(result);
            }
        });
    }
}

@Component({
    selector: 'edit-setting-dialog',
    templateUrl: 'edit_setting_dialog.component.html',
    styleUrls: ['edit_setting_dialog.component.scss']
})
export class EditSettingsDialog {

    constructor(
        public dialogRef: MatDialogRef<EditSettingsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Settings) { }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}
