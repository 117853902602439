<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<h1 class="content mt-3">Provider Billing</h1>
<mat-divider class="content"></mat-divider>
<div>
    <mat-tab-group class="content" (selectedTabChange)="changeTab()">
        <mat-tab label="Unpaid">
            <div class="scrollable-table content mt-5">
                <table
                    mat-table
                    [dataSource]="providerPayments"
                    class="mat-elevation-z8"
                >
                    <ng-container matColumnDef="Service Provider">
                        <th mat-header-cell *matHeaderCellDef>
                            Service Provider
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                element.serviceProvider.firstName +
                                    element.serviceProvider.lastName
                            }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Stripe Account ID">
                        <th mat-header-cell *matHeaderCellDef>
                            Stripe Account ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.serviceProvider.stripeAccountId || 'Not Set'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Bill Breakdown">
                        <th mat-header-cell *matHeaderCellDef>
                            Bill Breakdown
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon
                                class="clickable"
                                (click)="
                                    openBillBreakdown(element.orderServicePlans, element.transactionPercent)
                                "
                                >payments</mat-icon
                            >
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedUnpaidColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedUnpaidColumns"
                    ></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                            No Unpaid Providers
                        </td>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab label="Paid">
            <div class="scrollable-table content mt-5">
                <table
                    mat-table
                    [dataSource]="providerPayments"
                    class="mat-elevation-z8"
                >
                    <ng-container matColumnDef="Service Provider">
                        <th mat-header-cell *matHeaderCellDef>
                            Service Provider
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{
                                element.serviceProvider.firstName +
                                    element.serviceProvider.lastName
                            }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Stripe Account ID">
                        <th mat-header-cell *matHeaderCellDef>
                            Stripe Account ID
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.serviceProvider.stripeAccountId || 'Not Set'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Bill Breakdown">
                        <th mat-header-cell *matHeaderCellDef>
                            Bill Breakdown
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon
                                class="clickable"
                                (click)="
                                    openBillBreakdown(element.orderServicePlans, element.transactionPercent)
                                "
                                >payments</mat-icon
                            >
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedUnpaidColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedUnpaidColumns"
                    ></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                            No Unpaid Providers
                        </td>
                    </tr>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
