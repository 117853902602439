import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderServicePlan } from 'src/app/models/order_service_plan.mode';
import { OrderServicePlanService } from 'src/app/services/order_service_plan.service';
import { PaidConfirmComponent } from '../../paid-confirm/paid-confirm.component';

@Component({
	selector: 'app-payment-breakdown-dialog',
	templateUrl: './payment-breakdown-dialog.component.html',
	styleUrls: ['./payment-breakdown-dialog.component.scss']
})
export class PaymentBreakdownDialogComponent implements OnInit {
	loading = false;
	displayedColumns: string[] = ['Service Plan', 'Customer', 'Date of Purchase', 'Total Sale', 'Amount Paid To Provider', 'Paid?'];
	orderServicePlans: OrderServicePlan[] = [];
	updateList: OrderServicePlan[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<PaymentBreakdownDialogComponent>,
		private orderServicePlanService: OrderServicePlanService,
		public dialog: MatDialog
	) { }

	ngOnInit() {
		this.orderServicePlans = this.data.orderServicePlansData;
	}

	closeDialog() {
		this.dialogRef.close();
	}

	addToUpdateList(orderServicePlan: OrderServicePlan): void {
		this.loading = true;
		orderServicePlan.providerIsPaid = !orderServicePlan.providerIsPaid;
		const matchingIndex = this.updateList.findIndex(x => x.orderServicePlanId == orderServicePlan.orderServicePlanId);
		if (matchingIndex == -1) {
			this.updateList.push(orderServicePlan);
		} else {
			this.updateList[matchingIndex].providerIsPaid == !this.updateList[matchingIndex].providerIsPaid;
		}
		this.loading = false;
	}

	openConfirmDialog(): void {
		this.dialog.open(PaidConfirmComponent, {
			data: this.updateList,
			width: '250px'
		});
	}
	saveUpdatedList(): void {
		this.loading = true;
		this.updateList.forEach((orderServicePlan: OrderServicePlan) => {
			this.orderServicePlanService.put([orderServicePlan.orderServicePlanId], orderServicePlan).subscribe(() => {
				this.loading = false;
			}, () => { this.loading = false });
		})
	}

	getAmountPaidToProvider(orderServicePlan: OrderServicePlan): number {
		return orderServicePlan.total - (orderServicePlan.total * (this.data.transactionPercentData / 100));
	}
}

