import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ACHRecord } from 'src/app/models/ach_record.model';
import { ACHRecordService } from 'src/app/services/ach_record_service';
import { ViewAchDataDialogComponent } from '../ach-records/view-ach-data-dialog/view-ach-data-dialog.component';

@Component({
  selector: 'app-employee-ach-records',
  templateUrl: './employee-ach-records.component.html',
  styleUrls: ['./employee-ach-records.component.scss']
})
export class EmployeeAchRecordsComponent implements OnInit {
  achRecords: ACHRecord[] = [];
  savingACHSetUpChange: boolean = false;
  displayedColumns: string[] = [
    'Provider',
    'Role',
    'View ACH Data'
  ];

  constructor(
    private achRecordService: ACHRecordService,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAllAchRecords();
  }

  getAllAchRecords(): void {
    this.savingACHSetUpChange = true;
    const map = new Map();
    this.achRecordService.getAll(['Employee'], map).subscribe((achRecords) => {
      this.achRecords = achRecords;
      this.savingACHSetUpChange = false;
    }),
      () => {
        this.savingACHSetUpChange = false;
      };
  }

  openACHDataDialog(achRecord: ACHRecord): void {
    const dialogRef = this.dialog.open(ViewAchDataDialogComponent, {
      data: achRecord,
      width: '300px'
    });
  }

}
