import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';

// Material Components
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';

// Our Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { authInterceptorProviders } from './services/auth_intercepter.service';
import { errorInterceptorProviders } from './services/error_interceptor.service';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { UpsertEmployeeComponent } from './components/upsert_employee/upsert_employee.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { EmployeeComponent } from './components/employee/employee.component';
import { AuthGuardService } from './services/auth_guard_service.service';
import { PhoneMaskDirective } from './utils/validators/phone_mask.directive';
import { ReviewServiceApplicationComponent } from './components/review_service_application/review_service_application.component';
import { ServiceApplicationComponent } from './components/service_application/service_application.component';
import { ServiceTypeTableComponent } from './components/service_type_table/service_type_table.component';
import { AdminSettingsComponent, EditSettingsDialog } from './components/admin_settings/admin_settings.component';
import { AdminErrorDialog } from './components/error_dialog/error_dialog.component';
import { ProviderTableComponent } from './components/provider_table/provider_table.component';
import { CustomerTableComponent } from './components/customer-table/customer_table.component';
import { AchRecordsComponent } from './components/ach-records/ach-records.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { ProviderBillingComponent } from './components/provider-billing/provider-billing.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PaymentBreakdownDialogComponent } from './components/provider-billing/payment-breakdown-dialog/payment-breakdown-dialog/payment-breakdown-dialog.component';
import { PaidConfirmComponent } from './components/provider-billing/paid-confirm/paid-confirm.component';
import { ViewAchDataDialogComponent } from './components/ach-records/view-ach-data-dialog/view-ach-data-dialog.component';
import { EmployeeAchRecordsComponent } from './components/employee-ach-records/employee-ach-records.component';
import { AutocompleteOffDirective } from './components/ach-update/auto-complete-off.directive';
import { DigitOnlyDirective } from './components/ach-update/only-number.directive';
import { AchUpdateComponent } from './components/ach-update/ach-update.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavbarComponent,
        HomeComponent,
        UpsertEmployeeComponent,
        EmployeeComponent,
        ServiceApplicationComponent,
        ReviewServiceApplicationComponent,
        ServiceTypeTableComponent,
        PhoneMaskDirective,
        AdminSettingsComponent,
        AdminErrorDialog,
        EditSettingsDialog,
        ProviderTableComponent,
        CustomerTableComponent,
        AchRecordsComponent,
        ProviderBillingComponent,
        PaymentBreakdownDialogComponent,
        PaidConfirmComponent,
        ViewAchDataDialogComponent,
        EmployeeAchRecordsComponent,
        AutocompleteOffDirective,
        DigitOnlyDirective,
        AchUpdateComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        MatButtonModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatOptionModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTableModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatMenuModule,
        ClipboardModule,
        MatCardModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
    ],
    exports: [
        PhoneMaskDirective,
    ],
    providers: [
        authInterceptorProviders,
        errorInterceptorProviders,
        [AuthGuardService],
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
