import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderServicePlan } from 'src/app/models/order_service_plan.mode';
import { OrderServicePlanService } from 'src/app/services/order_service_plan.service';

@Component({
  selector: 'app-paid-confirm',
  templateUrl: './paid-confirm.component.html',
  styleUrls: ['./paid-confirm.component.scss']
})
export class PaidConfirmComponent implements OnInit {
  loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderServicePlan[],
    private orderServicePlanService: OrderServicePlanService,
    public dialogRef: MatDialogRef<PaidConfirmComponent>,


  ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.loading = true;
    this.data.forEach((orderServicePlan: OrderServicePlan) => {
      this.orderServicePlanService.put([orderServicePlan.orderServicePlanId], orderServicePlan).subscribe(() => {
        this.loading = false;

      }, () => { 
        this.loading = false 
      });
    });
    this.closeDialog();
  }
}
