import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {
    opened = true;

    routes = new Map([
        ['home', 'Home'],
        ['service-application', 'Provider Applications'],
        ['customers', 'Customers'],
        ['providers', 'Providers'],
        ['update-ach-record', 'My Payment Info']
    ]);

    adminRoutes = new Map([
        ['home', 'Home'],
        ['employee', 'Employees'],
        ['service-application', 'Provider Applications'],
        ['service-type', 'Service Types'],
        ['ach-records', 'Provider-ACH Records'],
        ['employee-ach-records', 'Employee-ACH Records'],
        ['provider-billing', 'Provider Billing'],
        ['settings', 'Settings'],
        ['customers', 'Customers'],
        ['providers', 'Providers'],
        ['update-ach-record', 'My Payment Info']
    ]);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tokenStorage: TokenStorageService,
        private tokenStorageService: TokenStorageService,
    ) { }

    get getLoggedInUserRole(): string {
        return this.tokenStorageService.getUser()?.employeeRole ?? '';
    }

    logout(): void {
        sessionStorage.clear();
        this.router.navigate(['login']);
    }

    get showSidenav(): boolean {

        if (!this.isUserLoggedIn) {
            return false;
        } else {
            return this.opened;
        }
    }

    toggleSidenav(): void {
        this.opened = !this.opened;
    }

    get isUserLoggedIn(): boolean {
        if (this.tokenStorage.getUser() != null && this.tokenStorage.getToken() != null) {
            return true;
        }
        return false;
    }

    isMobile() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < 768) {
            return true;
        } else {
            return false;
        }
    }
}
