import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeService } from 'src/app/services/employee_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

@Component({
    selector: 'employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
    employee!: Employee;
    employees: Employee[] = [];
    employeeDataSource = new MatTableDataSource<Employee>([]);
    employeeColumnHeaders: string[] = ['name', 'emailAddress', 'role', 'action'];
    loading = false;

    constructor(
        private router: Router,
        private storage: TokenStorageService,
        private employeeService: EmployeeService,
    ) {}

    get isAdmin(): boolean {
        if (this.employee != null && this.employee.employeeRole === 'Admin') {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        this.employee = this.storage.getUser();
        if (this.employee.employeeRole === 'Admin') {
            this.getAllEmployees();
        }
    }

    getAllEmployees(): void {
        this.loading = true;

        this.employeeService.getAll(['All'], new Map()).subscribe(x => {
            this.employees = x;
            this.loading = false;
            this.refreshEmployeeTable();
        }, () => this.loading = false);
    }

    createEmployee(): void {
        this.router.navigate(['create-employee']);
    }

    updateEmployee(id: string): void {
        this.router.navigate(['update-employee', id]);
    }

    refreshEmployeeTable(): void {
        this.employeeDataSource.data = this.employees;
    }
}
