<h1 mat-dialog-title>Setting</h1>

<div mat-dialog-content>
    <mat-form-field class="width-400">
        <mat-label>{{data.settingKey}}</mat-label>
        <input matInput [(ngModel)]="data.settingValue">
    </mat-form-field>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-button (click)="onCancelClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Save</button>
</div>