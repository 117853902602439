<h3 mat-dialog-title>ACH Data</h3>
<mat-dialog-content>
    <form [formGroup]="achRecordFormGroup" class="content">
        <div class="flex-row">
            <mat-form-field class="input mr-2 flex-0" appearance="outline">
                <mat-label>Bank Name</mat-label>
                <input
                    type="text"
                    formControlName="bankName"
                    matInput
                />
            </mat-form-field>

            <mat-form-field class="input flex-0 mr-2" appearance="outline">
                <mat-label>Routing Number</mat-label>
                <input
                    [type]="hideRoutingNumber ? 'password' : 'text'"
                    formControlName="routingNumber"
                    matInput
                />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="hideRoutingNumber = !hideRoutingNumber"
                    [attr.aria-label]="'Hide Routing Number'"
                    [attr.aria-pressed]="hideRoutingNumber"
                >
                    <mat-icon>{{
                        hideRoutingNumber ? "visibility_off" : "visibility"
                    }}</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="input flex-0" appearance="outline">
                <mat-label>Account Number</mat-label>
                <input
                    [type]="hideAccountNumber ? 'password' : 'text'"
                    formControlName="accountNumber"
                    matInput
                />
                <button
                    mat-icon-button
                    matSuffix
                    (click)="hideAccountNumber = !hideAccountNumber"
                    [attr.aria-label]="'Hide Account Number'"
                    [attr.aria-pressed]="hideAccountNumber"
                >
                    <mat-icon>{{
                        hideAccountNumber ? "visibility_off" : "visibility"
                    }}</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="warn" mat-dialog-close>Close</button>
</mat-dialog-actions>
