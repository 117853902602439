<div class="flex-row padding-30">
    <div class="flex-1"></div>

    <div class="flex-3">
        <div class="flex-1 flex-row">           
            <mat-form-field class="form-group flex-row max-form-width">
                <mat-label>New Service</mat-label>
                <input matInput type="text" autocomplete="false" [(ngModel)]="newServiceTypeName">
                <button  mat-button *ngIf="newServiceTypeName" matSuffix mat-icon-button aria-label="Clear" (click)="newServiceTypeName=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>            
    
            <div class="margin-left-30">
                <button mat-raised-button
                    *ngIf="!loading"
                    color="primary"
                    (click)="addServiceType()"
                    class="span-form margin-tb-10">
                    <span style="vertical-align: bottom;">+&nbsp;&nbsp;</span> Add Service
                </button>
                <mat-spinner [diameter]="70" *ngIf="loading"></mat-spinner>  
                <p class="invalid-service" *ngIf="serviceTypeInvalid">Invalid Service Type</p>
                <p class="invalid-service" *ngIf="duplicateServiceType">Duplicate Service Type</p>
                <p class="valid-service" *ngIf="successAdd">Service Type Added</p>    
            </div>
        </div>
        

        
        
        <h3 class="underlined">Service Types</h3>

        <mat-progress-bar *ngIf="loading" style="height: 5px; width: 100%;" mode="indeterminate"></mat-progress-bar>

        <div class="scrollable-table">
            <table mat-table [dataSource]="serviceTypes" class="mat-elevation-z8">
                <ng-container matColumnDef="name">            
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>
        
                <ng-container matColumnDef="status">            
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{element.serviceTypeStatus}}</td>
                </ng-container>
        
                <ng-container matColumnDef="dateModified">            
                    <th mat-header-cell *matHeaderCellDef>Date Updated</th>
                    <td mat-cell *matCellDef="let element">{{element.updateDateUTC | date:'shortDate'}}</td>
                </ng-container>
    
                <ng-container matColumnDef="action">            
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button 
                                [disabled]="loading" 
                                (click)="getAllServiceTypes()" 
                                class="span-form margin-tb-10 float-right">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="element.serviceTypeStatus == 'ACTIVE'"
                                mat-icon-button 
                                [disabled]="loading" 
                                (click)="toggleServiceType(element.serviceTypeId, true)" 
                                class="span-form margin-tb-10 float-right">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <button *ngIf="element.serviceTypeStatus == 'INACTIVE'"
                                mat-icon-button 
                                [disabled]="loading" 
                                (click)="toggleServiceType(element.serviceTypeId, false)" 
                                class="span-form margin-tb-10 float-right">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="serviceTypeColumnHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: serviceTypeColumnHeaders;"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No services found.</td>
                </tr>
            </table>
        </div>   
    </div>
    
    <div class="flex-1"></div>
</div>

