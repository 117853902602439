import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderServicePlan } from 'src/app/models/order_service_plan.mode';
import { ProviderPayment } from 'src/app/models/provider_payment.model';
import { ProviderPaymentService } from 'src/app/services/provider-payment.service';
import { PaymentBreakdownDialogComponent } from './payment-breakdown-dialog/payment-breakdown-dialog/payment-breakdown-dialog.component';

@Component({
    selector: 'app-provider-billing',
    templateUrl: './provider-billing.component.html',
    styleUrls: ['./provider-billing.component.scss']
})
export class ProviderBillingComponent implements OnInit {
    loading: boolean = false;
    currentTab = 'UNPAID';
    providerPayments: ProviderPayment[] = [];
    displayedUnpaidColumns: string[] = ['Service Provider', "Stripe Account ID", 'Bill Breakdown'];
    displayedPaidColumns: string[] = ['Service Provider', 'Stripe Account ID', 'Paid Plans'];

    constructor(
        private providerPaymentService: ProviderPaymentService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getProviderPayments();
    }

    getProviderPayments(): void {
        this.providerPayments = [];
        if (this.currentTab == 'UNPAID') {
            this.loading = true;
            const queryParams = new Map([
                ["isPaid", false],
            ]);
            this.providerPaymentService.get(['ProviderPayments'], queryParams).subscribe((providerPayments: ProviderPayment[]) => {
                this.providerPayments = providerPayments;
                this.loading = false;
            }, () => { this.loading = false; })
        } else {
            this.loading = true;
            const queryParams = new Map([
                ["isPaid", true],
            ]);
            this.providerPaymentService.get(['ProviderPayments'], queryParams).subscribe((providerPayments: ProviderPayment[]) => {
                this.providerPayments = providerPayments;
                this.loading = false;
            }, () => { this.loading = false; })
        }
    }

    changeTab(): void {
        if (this.currentTab == 'UNPAID') {
            this.currentTab = 'PAID';
        } else {
            this.currentTab = 'UNPAID';
        }

        this.getProviderPayments();
    }

    openBillBreakdown(orderServicePlans: OrderServicePlan[], transactionPercent: number): void {
        const dialogRef = this.dialog.open(PaymentBreakdownDialogComponent, {
            data: {
                orderServicePlansData: orderServicePlans,
                transactionPercentData: transactionPercent
            },
            width: '80%'
        });

        dialogRef.afterClosed().subscribe(() => {
            this.getProviderPayments();
        });
    }


}
