import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationNote } from 'src/app/models/application_note.model';
import { ServiceApplicationReview } from 'src/app/models/service_application_review.model';
import { ServiceDocument } from 'src/app/models/service_document.model';
import { ApplicationNoteService } from 'src/app/services/application_note_service.service';
import { ServiceApplicationEmployeeService } from 'src/app/services/service_application_employee_service.service';
import { ServiceApplicationService } from 'src/app/services/service_application_service';
import { ServiceDocumentService } from 'src/app/services/service_document_service.service';
import { TokenStorageService } from 'src/app/services/token_storage.service';

enum ApprovalStatuses {
    Approved = 'Approved',
    Denied = 'Denied',
    NeedsWork = 'Started',
}

@Component({
    selector: 'review-service-application',
    templateUrl: './review_service_application.component.html',
    styleUrls: ['./review_service_application.component.scss'],
})
export class ReviewServiceApplicationComponent implements OnInit {

    serviceApplication?: ServiceApplicationReview;

    loadingServiceApplication = false;
    loadingNotes = false;
    demographicsValid = false;
    filesValid = false;
    servicePlansValid = false;
    saving = false;

    // notes
    note = '';
    applicationNotes: ApplicationNote[] = [];
    applicationNoteDataSource = new MatTableDataSource<ApplicationNote>([]);
    applicationNoteColumnHeaders: string[] = ['name', 'employee', 'date', 'action'];

    filingTypes = new Map([
        ['UNMARRIED_INDIVIDUAL', 'Unmarried Individual'],
        ['HEAD_OF_HOUSEHOLD', 'Head of Household'],
        ['MARRIED_FILING_JOINTLY', 'Married Filing Jointly'],
    ]);

    salaryTypes = new Map([
        ['YEARLY', 'Yearly Income'],
        ['NINETY_DAYS', '90 Day Income'],
        ['MONTHLY', '30 Day Income'],
        ['BI_WEEKLY', 'Bi-weekly Income'],
        ['WEEKLY', 'Weekly Income']
    ]);

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private serviceApplicationService: ServiceApplicationService,
        private serviceDocumentService: ServiceDocumentService,
        private tokenStorageService: TokenStorageService,
        private serviceApplicationEmployeeService: ServiceApplicationEmployeeService,
        private applicationNoteService: ApplicationNoteService,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.loadingServiceApplication = true;
            const id = params.get('id');

            if (id != null) {
                this.serviceApplicationService
                    .get([id, 'Review'], new Map())
                    .subscribe((application: any) => {
                        this.serviceApplication = application;
                        this.getNotes();
                        this.loadingServiceApplication = false;
                    }
                );
            } 
        });
    }

    get getDemographicReviewStatus(): string {
        return this.demographicsValid ? 'Reviewed' : 'Unreviewed';
    }

    get getFilesReviewStatus(): string {
        return this.filesValid ?  'Reviewed' : 'Unreviewed';
    }

    get getServicePlansReviewStatus(): string {
        return this.servicePlansValid ?  'Reviewed' : 'Unreviewed';
    }

    downloadFile(serviceDocument: ServiceDocument): void {
        if (serviceDocument?.serviceDocumentId != null) {
            this.serviceDocumentService.download(serviceDocument.serviceDocumentId).subscribe((x: any) => {
                const binaryString = window.atob(x.data);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                const a = window.document.createElement('a');
                const fileURl = a.href = window.URL.createObjectURL(new Blob([bytes], { type: serviceDocument.contentType }));
                window.open(fileURl);
            });
        }
    }

    onDenyServiceApplication(): void {
        const user = this.tokenStorageService.getUser();

        const employeeId = user.id;
        const serviceApplicationId = this.serviceApplication?.serviceApplicationId;
        const approvalStatus = ApprovalStatuses.Denied.toString();

        if (user != null && this.serviceApplication?.serviceApplicationId != null) {
            this.saving = true;

            this.serviceApplicationEmployeeService
                .post([], { employeeId, serviceApplicationId, approvalStatus })
                .subscribe(_ => {
                        this.saving = false;
                        this.router.navigate(['service-application']);
                }, (_) => this.saving = false
            );
        }
    }

    onApproveServiceApplication(): void {
        const user = this.tokenStorageService.getUser();

        const employeeId = user.id;
        const serviceApplicationId = this.serviceApplication?.serviceApplicationId;
        const approvalStatus = ApprovalStatuses.Approved.toString();

        if (user != null && this.serviceApplication?.serviceApplicationId != null) {
            this.saving = true;

            this.serviceApplicationEmployeeService
                .post([], { employeeId, serviceApplicationId, approvalStatus })
                .subscribe(_ => {
                        this.saving = false;
                        this.router.navigate(['service-application']);
                }, (_) => this.saving = false
            );
        }
    }

    onNeedsWorkServiceApplication(): void {
        const user = this.tokenStorageService.getUser();

        const employeeId = user.id;
        const serviceApplicationId = this.serviceApplication?.serviceApplicationId;
        const approvalStatus = ApprovalStatuses.NeedsWork.toString();

        if (user != null && this.serviceApplication?.serviceApplicationId != null) {
            this.saving = true;

            this.serviceApplicationEmployeeService
                .post([], { employeeId, serviceApplicationId, approvalStatus })
                .subscribe(_ => {
                        this.saving = false;
                        this.router.navigate(['service-application']);
                }, (_) => this.saving = false
            );
        }
    }

    postNote(): void {
        const user = this.tokenStorageService.getUser();

        const employeeId = user.id;
        const serviceApplicationId = this.serviceApplication?.serviceApplicationId;
        const note = this.note;

        if (user != null && this.serviceApplication?.serviceApplicationId != null) {
            this.applicationNoteService
                .post([], { employeeId, serviceApplicationId, note })
                .subscribe(_ => {
                    this.getNotes();
                    this.note = '';
                }
            );
        }
    }

    getNotes(): void {
        this.loadingNotes = true;

        if (this.serviceApplication?.serviceApplicationId != null) {
            const serviceApplicationId = this.serviceApplication.serviceApplicationId;

            this.applicationNoteService.get(['ServiceApplication', serviceApplicationId], new Map()).subscribe(
                x => {
                    this.applicationNotes = x;
                    this.loadingNotes = false;
                    this.refreshApplicatioNoteTable();
                },
                (_) => {
                    this.loadingNotes = false;
                }
            );
        }
    }

    deleteNote(applicationNoteId: string): void {
        if (this.serviceApplication?.serviceApplicationId != null) {
            this.applicationNoteService.delete(applicationNoteId)
            .subscribe(_ => {
                this.getNotes();
            });
        }
    }

    refreshApplicatioNoteTable(): void {
        this.applicationNoteDataSource.data = this.applicationNotes;
    }

    goBack(): void {
        this.router.navigate(['service-application']);
    }
}
