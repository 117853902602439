<div class="ach-form-border content mt-5">
    <h1 class="content mt-3">Account Direct Deposit Payment Account</h1>

    <h3 class="content" *ngIf="!hasSetupACHRecord">
        Set up your bank account to receive payments
    </h3>

    <h4 class="content account-updated" *ngIf="accountUpdated">
        Account Updated
    </h4>

    <h3 class="content" *ngIf="hasSetupACHRecord">
        Current Account, Edit Fields to Update to New Account
    </h3>

    <p class="content">*Account Numbers must be 5-17 characters</p>

    <form [formGroup]="achRecordFormGroup" autocomplete="off" class="content">
        <div class="flec-row">
            <mat-form-field class="input mr-2 flex-0" appearance="outline">
                <mat-label>Bank Name</mat-label>

                <input type="text" maxlength="50" autocomplete="false" formControlName="bankName" matInput
                    autocompleteOff />
            </mat-form-field>

            <mat-form-field class="input flex-0 mr-2" appearance="outline">
                <mat-label>Routing Number</mat-label>

                <input [type]="hideRoutingNumber ? 'password' : 'text'" formControlName="routingNumber"
                    autocomplete="false" matInput minlength="9" maxlength="9" digitOnly autocompleteOff
                    #routingNumber />

                <button mat-icon-button matSuffix (click)="hideRoutingNumber = !hideRoutingNumber"
                    [attr.aria-label]="'Hide Routing Number'" [attr.aria-pressed]="hideRoutingNumber">
                    <mat-icon>{{ hideRoutingNumber ? "visibility_off" : "visibility" }}</mat-icon>
                </button>

                <mat-hint align="end">{{ routingNumber.value.length }} / 9</mat-hint>
            </mat-form-field>

            <mat-form-field class="input flex-0" appearance="outline">
                <mat-label>Account Number</mat-label>

                <input [type]="hideAccountNumber ? 'password' : 'text'" formControlName="accountNumber" minlength="5"
                    maxlength="17" autocomplete="false" matInput digitOnly autocompleteOff />

                <button mat-icon-button matSuffix (click)="hideAccountNumber = !hideAccountNumber"
                    [attr.aria-label]="'Hide Account Number'" [attr.aria-pressed]="hideAccountNumber">
                    <mat-icon>{{ hideAccountNumber ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <button type="submit" [disabled]="achRecordFormGroup.invalid || achRecordFormGroup.pristine"
            (click)="submitACHRecord()" mat-raised-button color="primary" class="mt-2 mb-2">
            Submit
        </button>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.bankName.hasError('required')">
            *Bank Name is Required
        </p>

        <p class="error-message"
            *ngIf="achRecordFormGroup.controls.routingNumber.hasError('pattern') && achRecordFormGroup.controls.routingNumber.dirty">
            *Invalid Routing Number, Must be all numbers to submit
        </p>

        <p class="error-message"
            *ngIf="achRecordFormGroup.controls.accountNumber.hasError('pattern') && achRecordFormGroup.controls.accountNumber.dirty">
            *Invalid Account Number, Must be all numbers to submit
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.routingNumber.hasError('required')">
            *Routing Number is Required
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.accountNumber.hasError('required')">
            *Account Number is Required
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.routingNumber.hasError('maxlength')">
            *Routing Number must be 9 characters
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.routingNumber.hasError('minlength')">
            *Routing Number must be 9 characters
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.accountNumber.hasError('maxlength')">
            *Account Number must be between 5-17 characters
        </p>

        <p class="error-message" *ngIf="achRecordFormGroup.controls.accountNumber.hasError('minlength')">
            *Account Number must be between 5-17 characters
        </p>
    </form>
</div>