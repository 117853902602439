import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AchRecordsComponent } from './components/ach-records/ach-records.component';
import { AchUpdateComponent } from './components/ach-update/ach-update.component';
import { AdminSettingsComponent } from './components/admin_settings/admin_settings.component';
import { CustomerTableComponent } from './components/customer-table/customer_table.component';
import { EmployeeAchRecordsComponent } from './components/employee-ach-records/employee-ach-records.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ProviderBillingComponent } from './components/provider-billing/provider-billing.component';
import { ProviderTableComponent } from './components/provider_table/provider_table.component';
import { ReviewServiceApplicationComponent } from './components/review_service_application/review_service_application.component';
import { ServiceApplicationComponent } from './components/service_application/service_application.component';
import { ServiceTypeTableComponent } from './components/service_type_table/service_type_table.component';
import { UpsertEmployeeComponent } from './components/upsert_employee/upsert_employee.component';
import { AuthGuardService } from './services/auth_guard_service.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'create-employee',
    component: UpsertEmployeeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'update-employee/:id',
    component: UpsertEmployeeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'service-application',
    component: ServiceApplicationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'service-application/:id',
    component: ReviewServiceApplicationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'service-type',
    component: ServiceTypeTableComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'settings',
    component: AdminSettingsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'providers',
    component: ProviderTableComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'customers',
    component: CustomerTableComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'ach-records',
    component: AchRecordsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'employee-ach-records',
    component: EmployeeAchRecordsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'update-ach-record',
    component: AchUpdateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'provider-billing',
    component: ProviderBillingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
