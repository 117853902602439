import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { User } from 'src/app/models/user.model';
import { TokenStorageService } from 'src/app/services/token_storage.service';
import { UserService } from 'src/app/services/user_service.service';
import { MatDialog } from '@angular/material/dialog';
import { PaginationHeaderData } from 'src/app/models/pagination-header-data.model';

@Component({
    selector: 'customer-table',
    templateUrl: './customer_table.component.html',
    styleUrls: ['./customer_table.component.scss']
})
export class CustomerTableComponent implements OnInit {
    employee!: Employee;
    customers: User[] = [];
    currentPage = 1;
    moreCustomersToLoad = false;
    loading = false;

    customerColumnHeaders: string[] = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'primaryPhoneNumber',
        'secondaryPhoneNumber',
        'emailAddress',
        'address',
        'action'
    ];

    constructor(
        private userService: UserService,
        private tokenStorageService: TokenStorageService,
        private router: Router,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.employee = this.tokenStorageService.getUser();
        if (this.employee.employeeRole === 'Admin' || this.employee.employeeRole === 'Associate') {
            this.getCustomers();
        } else {
            this.router.navigate(['home']);
        }
    }

    getCustomers(): void {
        this.loading = true;
        const queryParams = new Map([
            ["pageNumber", [this.currentPage]],
        ])

        this.userService.getPaginatedUsers(["Paged"], queryParams).subscribe(result => {
            let paginationData: PaginationHeaderData = JSON.parse(result.headers.get('x-pagination'));
            this.moreCustomersToLoad = paginationData.hasNext;
            this.customers = [...this.customers, ...result.body];
            this.loading = false;
        }, () => { this.loading = false });
    }

    loadMoreCustomers(): void {
        this.currentPage++;
        this.getCustomers();
    }

    resetLoad(): void {
        this.customers = [];
        this.currentPage = 1;
        this.getCustomers();
    }
}


