interface ISettings {
    settingId: string;
    settingKey: string;
    settingValue: string;
}

export class Settings {
    public settingId: string;
    public settingKey: string;
    public settingValue: string;


    constructor();
    constructor(object: ISettings);
    constructor(object?: any) {
        this.settingId = object && object.settingId || null;
        this.settingKey = object && object.settingKey || null;
        this.settingValue = object && object.settingValue || null;
    }
}
