import { Injectable } from '@angular/core';
import { AbstractService } from './abstract_service.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceApplicationService extends AbstractService {
  path = 'ServiceApplication';
  reviewPath = 'Review';
}
