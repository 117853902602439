<mat-toolbar class="navbar">
    <button
        *ngIf="isUserLoggedIn"
        mat-icon-button
        class="menu-button"
        (click)="toggleSidenav()"
    >
        <mat-icon>menu</mat-icon>
    </button>

    <a routerLink="/home">
        <img src="../../../assets/images/Logo.png" class="logo ml-4" />
    </a>

    <span class="example-spacer"></span>

    <!-- <a href="https://healthpossibleinc.org/" class="link-styling">HOME</a>
    <a href="https://healthpossibleinc.org/join-now/" class="link-styling">JOIN NOW</a>
    <a href="https://healthpossibleinc.org/whoweare/" class="link-styling">WHO WE ARE</a>
    <a href="https://healthpossibleinc.org/about/" class="link-styling">OUR TEAM</a>
    <a href="https://healthpossibleinc.org/contact/" class="link-styling">CONTACT</a>
    <a href="https://healthpossibleinc.org/donate-2/" class="link-styling">DONATE</a> -->
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav [mode]="isMobile() ? 'over' : 'side'" [opened]="showSidenav">
        <mat-nav-list>
            <ng-container *ngIf="getLoggedInUserRole == 'Admin'">
                <a
                    mat-list-item
                    routerLink="{{ route.key }}"
                    routerLinkActive="active-link"
                    *ngFor="let route of adminRoutes | keyvalue"
                >
                    {{ route.value }}
                </a>
            </ng-container>

            <ng-container *ngIf="getLoggedInUserRole == 'Associate'">
                <a
                    mat-list-item
                    routerLink="{{ route.key }}"
                    routerLinkActive="active-link"
                    *ngFor="let route of routes | keyvalue"
                >
                    {{ route.value }}
                </a>
            </ng-container>
        </mat-nav-list>

        <button mat-flat-button class="logout-button" (click)="logout()">
            Logout
        </button>
    </mat-sidenav>

    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
